import "./App.css";

import styled from "styled-components";
import { Card, CardContent, CardHeader } from "@mui/material";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  .MuiCard-root {
    position: fixed;
    z-index: 1;
  }
  .MuiCardHeader-root {
    .MuiTypography-root {
      font-size: 16px;
    }
  }
  .MuiCardContent-root {
    height: 60vh;
    width: 70vw;
  }
  iframe {
    border: none;
  }
`;
const BackgroundImage = styled.div`
  height: 100vh;
  width: 100vw;
  background-size: contain;
  background-image: url("/images/desktop.jpg");
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 600px) {
    background-image: url("/images/mobile.jpg");
  }
`;

function App() {
  const search = window.location.search;
  console.log(search);
  return (
    <div className="App">
      <Container>
        <BackgroundImage></BackgroundImage>
        <Card>
          <CardHeader title="Videoberatung mit Bastian Berater"></CardHeader>
          <CardContent>
            <iframe
              width="100%"
              height="100%"
              title="poc"
              allow="camera;microphone;display-capture"
              src={`https://video-app-1946-6130-dev.twil.io${search}&passcode=62236019466130`}
              // src={`http://localhost:3000/?passcode=50445119466130${search.replace(
              //   "?",
              //   "&"
              // )}`}
            ></iframe>
          </CardContent>
        </Card>
      </Container>
    </div>
  );
}

export default App;
